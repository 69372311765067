@charset "utf-8";

@import "minimal-mistakes/skins/dirt"; // skin
@import "minimal-mistakes"; // main partials

html {
  font-size: 10px; // change to whatever

  @include breakpoint($medium) {
    font-size: 12px; // change to whatever
  }

  @include breakpoint($large) {
    font-size: 14px; // change to whatever
  }

  @include breakpoint($x-large) {
    font-size: 16px; // change to whatever
  }
}

.fluid-width-video-wrapper {
  min-height: 500px;
}

object, embed {
  min-height: 400px;
}

.xournal svg {
  width: 100%;
}

iframe {
  width: 100%;
}

iframe .bigframe {
  min-height: 800px;
}

.archive__item-teaser {
  height: 200px;
}

ul {
  padding-left: 30px !important;
}
